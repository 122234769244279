import { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useTranslation } from "next-i18next";

import { countries, getTextWidth } from "../../assets/data/countries";
import MapImgWebp from "../../public/imgs/map.webp";
import { support_format_webp } from "../../utils/functions";
import MaxWidth from "../MaxWidth";
import { screenSizes } from "../../utils/constants";

import ExpandButton from "../Buttons/ExpandButton";

import styles from "./Map.module.scss";

import MapImgPng from "../../public/imgs/map.png";
import GridContainer from "../GridContainer/GridContainer";

let openAnimation: any = null;

const Map = () => {
  const [imgSrc, setImgSrc] = useState(MapImgWebp.src);
  const [showAll, setShowAll] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [displayMap, setDisplayMap] = useState(false);
  const { t } = useTranslation("map");
  const mm = gsap.matchMedia();
  const listRef = `.${styles["countries-list"]}`;
  useLayoutEffect(() => {
    if (!support_format_webp(document)) {
      setImgSrc(MapImgPng.src);
    }
    mm.add(screenSizes, (context) => {
      let { isSmall } = context.conditions as any;
      if (!isSmall) {
        setDisplayMap(true);
      }
    });
  }, []);
  useLayoutEffect(() => {
    mm.add(screenSizes, () => {
      const tl = gsap.timeline();
      if (showAll) {
        openAnimation = tl.to(listRef, {
          height: "auto",
        });
      } else {
        openAnimation !== null && openAnimation.reverse();
        openAnimation = null;
      }
    });
  }, [showAll]);
  return (
    <MaxWidth>
      <GridContainer addclasses={[styles["container"]]}>
        <h3 className={styles["description"]}>{t("description")}</h3>
        <div className={styles["countries-list"]}>
          {countries.map((country, i) => {
            return (
              <h3
                key={i}
                onMouseEnter={() => {
                  setSelected(i);
                }}
                onMouseLeave={() => {
                  setSelected(null);
                }}
                style={{
                  color: i === selected ? "#036ab5" : "#263b47",
                  transition: "color 0.5s ease",
                }}
                className={styles["country-link"]}
              >
                {t(`countries.${country.title}`)}
              </h3>
            );
          })}
        </div>
        <ExpandButton
          title={showAll ? "скрыть" : "развернуть"}
          opened={showAll ? 1 : 0}
          onClick={() => {
            setShowAll((prev) => !prev);
          }}
          addclasses={[styles["expand-btn"]]}
        />
        {displayMap && (
          <div className={styles["map-container"]}>
            <svg viewBox="0 0 1720 870">
              <image href={imgSrc} width="100%" height="100%" />
              {countries.map((country, i) => {
                return (
                  <g key={i}>
                    <circle
                      cx={country.x + 12}
                      cy={country.y + 6}
                      r="12"
                      style={{ cursor: "pointer" }}
                      onMouseEnter={() => {
                        setSelected(i);
                      }}
                      onMouseLeave={() => {
                        setSelected(null);
                      }}
                      fill={i === selected ? "#263b47" : "#036ab5"}
                      className={styles["map-circle"]}
                    />
                  </g>
                );
              })}
              {/* чтобы заголовки рисовались поверх кружков */}
              {countries.map((country, i) => {
                const textLength = getTextWidth(
                  t(`countries.${country.title}`)
                );
                return (
                  <g key={i}>
                    {i === selected && (
                      <g className={`${styles["map-country-name"]}`}>
                        <rect
                          x={country.x}
                          y={country.y - 50}
                          rx={10}
                          width={textLength}
                          height="40"
                          fill="#263b47"
                        ></rect>
                        <text
                          x={country.x + 18}
                          y={country.y - 25}
                          font-size="14"
                          fill="#fff"
                          textLength={textLength - 36}
                        >
                          {t(`countries.${country.title}`).toUpperCase()}
                        </text>
                      </g>
                    )}
                  </g>
                );
              })}
            </svg>
          </div>
        )}
      </GridContainer>
    </MaxWidth>
  );
};

export default Map;
