import { FC, HTMLProps, useState } from "react";
import styles from "./Discuss.module.scss";
import classNames from "classnames";
import { useAppProvider } from "../../utils/appProvider";
import { useTranslation } from "next-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import GridContainer from "../GridContainer/GridContainer";
import TextField from "../TextField";
import Textarea from "../Textarea";
import PhoneNumber from "../PhoneField";
import Checkbox from "./Checkbox";
import OutlinedButton from "../Buttons/OutlinedButton/OutlinedButton";
import MaxWidth from "../MaxWidth/MaxWidth";
import { isSessionStorageEnabled } from "../../utils/functions";

export type Props = { addclasses?: string[] } & HTMLProps<HTMLDivElement>;

const Discuss: FC<Props> = (props) => {
  const { setShowFeedback, utmTags } = useAppProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation("common");
  const { addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const validation = Yup.object().shape({
    name: Yup.string().required(),
    phone: Yup.string().required().min(12).max(20),
    email: Yup.string().email().required(),
    termsChecked: Yup.bool().isTrue(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      phone: "",
      email: "",
      comment: "",
      termsChecked: false,
    },
    validationSchema: validation,
    onSubmit: (data) => {
      const { name, company, phone, email, comment } = data;
      let source = undefined;
      let campaign = undefined;
      let medium = undefined;
      if (isSessionStorageEnabled()) {
        source = sessionStorage.getItem("utm_source");
        campaign = sessionStorage.getItem("utm_campaign");
        medium = sessionStorage.getItem("utm_medium");
      } else {
        source = utmTags["utm_source"];
        campaign = utmTags["utm_campaign"];
        medium = utmTags["utm_medium"];
      }
      const formatted = {
        name,
        company,
        phone,
        email,
        comment,
        source,
        campaign,
        medium,
      };
      handleSubmit(formatted as any);
    },
  });

  const handleSubmit = async (data: { [key: string]: string }) => {
    setLoading(true);
    setError("");
    try {
      const res = await fetch("/api/contact", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        setShowFeedback(true);
        (window as any).ym(
          process.env.NEXT_PUBLIC_YANDEX_METRIC_ID,
          "reachGoal",
          "form_submission"
        );
        formik.resetForm();
      } else {
        if (res.status == 403) {
          throw new Error("requiredFieldsAreEmpty");
        } else {
          throw new Error("formError");
        }
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MaxWidth>
      <GridContainer addclasses={[className]}>
        <div className={styles["text"]}>
          <h3 className={styles["title"]}>Deep insight</h3>
          <h3>{t("discuss.description")}</h3>
        </div>
        <form className={styles["form"]} onSubmit={formik.handleSubmit}>
          <TextField
            name="name"
            id="name"
            addclasses={[styles["name"]]}
            value={formik.values.name}
            label={t("discuss.name")!}
            handleChange={formik.handleChange}
            handleBlur={() => formik.setFieldTouched("name")}
            error={!!formik.errors.name && formik.touched.name}
          />
          <TextField
            name="company"
            id="company"
            addclasses={[styles["company"]]}
            value={formik.values.company}
            label={t("discuss.company")!}
            handleChange={formik.handleChange}
            handleBlur={() => formik.setFieldTouched("company")}
            error={!!formik.errors.company && formik.touched.company}
          />
          <PhoneNumber
            name="phone"
            id="phone"
            value={formik.values.phone}
            addclasses={[styles["phone"]]}
            handleChange={(value: string) => {
              formik.setFieldValue("phone", value);
            }}
            handleBlur={() => formik.setFieldTouched("phone")}
            error={!!formik.errors.phone && formik.touched.phone}
          />
          <TextField
            name="email"
            id="email"
            value={formik.values.email}
            label="E-mail"
            addclasses={[styles["email"]]}
            handleChange={formik.handleChange}
            handleBlur={() => formik.setFieldTouched("email")}
            error={!!formik.errors.email && formik.touched.email}
          />
          <Textarea
            name="comment"
            id="comment"
            value={formik.values.comment}
            label={t("discuss.comment")!}
            addclasses={[styles["comment"]]}
            handleChange={formik.handleChange}
          />
          <Checkbox
            checked={formik.values.termsChecked ? 1 : 0}
            setChecked={() =>
              formik.setFieldValue("termsChecked", !formik.values.termsChecked)
            }
            error={
              !!formik.errors.termsChecked && !!formik.touched.termsChecked
                ? 1
                : 0
            }
            addclasses={[styles["checkbox"]]}
          />
          <OutlinedButton
            title={t("discuss.send")}
            addclasses={[styles["btn"]]}
            type="submit"
            light
          />
          {error && (
            <div className={`${styles["error__container"]}`}>
              <p className={`H4 ${styles["error__message"]}`}>
                {t(`discuss.${error}`)}
              </p>
            </div>
          )}
        </form>
      </GridContainer>
    </MaxWidth>
  );
};

export default Discuss;
