type Country = {
  x: number;
  y: number;
  title: string;
};

export const getTextWidth = (title: string) => {
  return title.length * 9.5 + 41;
};

export const countries: Country[] = [
  {
    x: 831,
    y: 298,
    title: "Germany",
  },
  {
    x: 772,
    y: 273,
    title: "UK",
  },
  { x: 772, y: 357, title: "Spain" },
  { x: 885, y: 337, title: "Bulgaria" },
  { x: 871, y: 304, title: "Poland" },
  { x: 840, y: 261, title: "Denmark" },
  { x: 951, y: 370, title: "Turkey" },
  { x: 333, y: 310, title: "USA" },
  { x: 1213, y: 369, title: "China" },
  { x: 1354, y: 387, title: "South Korea" },
  { x: 1255, y: 548, title: "Singapore" },
  { x: 1213, y: 462, title: "Thailand" },
  { x: 1360, y: 561, title: "Indonesia" },
  { x: 1242, y: 515, title: "Malaysia" },
  { x: 1012, y: 442, title: "UAE" },
  { x: 572, y: 593, title: "Brazil" },
  { x: 493, y: 728, title: "Argentina" },
  { x: 321, y: 400, title: "Mexico" },
  { x: 468, y: 540, title: "Columbia" },
  { x: 472, y: 679, title: "Chile" },
  { x: 387, y: 491, title: "Salvador" },
  { x: 915, y: 281, title: "Belarus" },
  { x: 1108, y: 310, title: "Kazakhstan" },
  { x: 987, y: 356, title: "Azerbaijan" },
  { x: 885, y: 685, title: "South Africa" },
  { x: 951, y: 560, title: "Kenya" },
  { x: 951, y: 523, title: "Ethiopia" },
  { x: 835, y: 412, title: "Tunisia" },
  { x: 987, y: 269, title: "Russia" },
  { x: 843, y: 349, title: "Italy" },
];
