import { FC, HTMLProps, useLayoutEffect } from "react";
import styles from "./ExpandButton.module.scss";
import classNames from "classnames";
import Arrow from "./arrow.svg";
import { NonBoolean } from "../../../utils/types";

export type Props = {
  title: string;
  opened?: NonBoolean;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const ExpandButton: FC<Props> = (props) => {
  const { addclasses, title, opened } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    { [styles["opened"]]: opened }
  );

  return (
    <div className={className} {...props}>
      {title}
      <Arrow className={styles["arrow"]} />
    </div>
  );
};

export default ExpandButton;
