import { FC, HTMLProps } from "react";
import styles from "./BigBlueBtn.module.scss";
import classNames from "classnames";

export type Props = {
  title: string;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const BigBlueBtn: FC<Props> = (props) => {
  const { title, onClick, addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  return (
    <div className={className} onClick={onClick}>
      <span className={styles["title"]}>{title}</span>
    </div>
  );
};

export default BigBlueBtn;
